import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { GenericException } from 'src/app/core/logic/exception';

import { DietitianId } from '../../dietitian/domain/dietitian';
import { PatientId } from '../../patient/domain/patient';
import { Document, DocumentProps } from '../domain/document/document';
import { DocumentNotFoundException } from '../domain/document/document_exceptions';
import { DocumentUpload } from '../domain/document/document_upload';
import { SharedDocumentId } from '../domain/shared_document/shared_document';

interface DocumentSchema {
  patientId: string;
  dietitianId: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  url: string | null;
  path: string;
  size: number;
  mimeType: string;
  sharedDocumentId: string | undefined;
  sharedAt: Timestamp | undefined;
}

@Injectable()
export class DocumentRepository {
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) {
    // do nothing
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<DocumentSchema>('documents', queryFn);
  }

  private toSchema(document: Document): DocumentSchema {
    return <DocumentSchema>{
      dietitianId: document.dietitianId.id.toString(),
      patientId: document.patientId.id.toString(),
      name: document.name,
      createdAt:
        document.createdAt !== undefined
          ? Timestamp.fromDate(document.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      url: document.url ?? null,
      path: document.path ?? null,
      size: document.size,
      mimeType: document.mimeType,
      sharedDocumentId: document.sharedDocumentId?.id.toString() ?? null,
      sharedAt:
        document.sharedAt !== undefined
          ? Timestamp.fromDate(document.sharedAt)
          : null,
    };
  }

  private fromSchema(schema: DocumentSchema, id: string): Document {
    return Document.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        patientId: PatientId.create(new UniqueEntityID(schema.patientId)),
        name: schema.name,
        createdAt: schema.createdAt?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
        url: schema.url ?? undefined,
        path: schema.path,
        size: schema.size ?? -1,
        mimeType: schema.mimeType ?? 'unknown',
        sharedDocumentId: schema.sharedDocumentId
          ? SharedDocumentId.create(new UniqueEntityID(schema.sharedDocumentId))
          : undefined,
        sharedAt: schema.sharedAt?.toDate() ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  private async fileExists(path: string): Promise<boolean> {
    let url: string;
    try {
      url = await firstValueFrom(this.storage.ref(path).getDownloadURL());
    } catch (error) {
      return false;
    }
    return url != null;
  }

  private async deleteFile(path: string): Promise<void> {
    if (!(await this.fileExists(path))) return;

    await firstValueFrom(this.storage.ref(path).delete());
  }

  async load(documentId: string): Promise<Document> {
    const snap = await firstValueFrom(this.collection().doc(documentId).get());
    if (!snap.exists || snap.data == null) {
      throw new DocumentNotFoundException();
    }

    return this.fromSchema(snap.data() as DocumentSchema, snap.id);
  }

  async findByDietitianId(
    dietitianId: string,
    asc = false,
  ): Promise<Document[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .orderBy('updatedAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findByPatientId(
    dietitianId: string,
    patientId: string,
    asc = false,
  ): Promise<Document[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .where('patientId', '==', patientId)
          .orderBy('updatedAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(document: Document): Promise<Document> {
    const schema = this.toSchema(document);
    const ref = await this.collection().add(schema);

    return this.fromSchema(schema, ref.id);
  }

  upload(document: Document): DocumentUpload {
    if (document.path == null || document.file == null) {
      throw new GenericException();
    }
    const fileRef = this.storage.ref(document.path);
    const task = fileRef.put(document.file, {
      contentType: document.mimeType,
    });

    return DocumentUpload.create({
      document,
      task,
    });
  }

  async save(document: Document): Promise<Document> {
    if (document.path == null || document.url == null) {
      throw new GenericException();
    }

    if (document.path == null || !(await this.fileExists(document.path))) {
      throw new GenericException();
    }

    // check for existing document file
    const snap = await firstValueFrom(
      this.collection().doc(document.id.toString()).get(),
    );
    const path = snap.data()?.path;
    // delete old file
    if (path && path != document.path) {
      await this.deleteFile(path);
    }

    const schema = this.toSchema(document);
    return this.collection()
      .doc(document.documentId.id.toString())
      .set(schema)
      .then(() =>
        this.fromSchema(schema, document.documentId.id.toString()).copyWith({
          file: document.file,
        } as DocumentProps),
      );
  }

  async delete(documentId: string): Promise<void> {
    const snap = await firstValueFrom(this.collection().doc(documentId).get());
    if (!snap.exists || snap.data == null) {
      throw new DocumentNotFoundException();
    }
    const path = snap.data()?.path;
    if (path) {
      await this.deleteFile(path);
    }

    return this.collection().doc(documentId).delete();
  }
}
