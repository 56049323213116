import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { UserId } from '../../auth/domain/user';
import { Picture } from './picture/picture';
import { Subscription } from './subscription/subscription';
import { SubscriptionFeature } from './subscription/subscription_feature';

// France, Belgique, Suisse, Luxembourg, Maroc, Sénégal, Royaume-Uni
export const FEATURED_DIETITIAN_COUNTRY_CODE = [
  '33',
  '32',
  '41',
  '352',
  '212',
  '221',
  '44',
];

export enum Gender {
  WOMAN = 'WOMAN',
  MAN = 'MAN',
  NB = 'NB',
}

export interface DietitianProps {
  userId: UserId;
  stripeCustomerId?: StripeCustomerId;
  gender?: Gender;
  email: string;
  emailVerified: boolean;
  notificationToken?: string;
  firstName: string;
  lastName: string;
  createdAt?: Date;
  updatedAt?: Date;
  phoneNumber: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  patientCount: number;
  activatedPatientCount: number;
  archivedPatientCount: number;
  encouragementCount: number;
  picture?: Picture;
  subscription?: Subscription;
  subscriptionFeatures?: { [key: string]: SubscriptionFeature };
  isAdmin?: boolean;
  msdpUser?: string;
  msdpPassword?: string;
  msdpId?: string;
  msdpLinkedAt?: Date;
  msdpKey?: string;
  lastNews?: Date;
  status?: string;
  adeliNumber?: string;
  patientsDefaultSort?: 'createdAt' | 'updatedAt' | 'lastActiveDate';
}

export class DietitianId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): DietitianId {
    return new DietitianId(id);
  }
}

export class StripeCustomerId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): StripeCustomerId {
    return new StripeCustomerId(id);
  }
}

export class Dietitian extends Entity<DietitianProps> {
  get dietitianId(): DietitianId {
    return DietitianId.create(this.id);
  }

  get userId(): UserId {
    return this.props.userId;
  }

  get stripeCustomerId(): StripeCustomerId | undefined {
    return this.props.stripeCustomerId;
  }

  get gender(): Gender | undefined {
    return this.props.gender;
  }

  get email(): string {
    return this.props.email;
  }

  get notificationToken(): string | undefined {
    return this.props.notificationToken;
  }

  get patientCount(): number {
    return this.props.patientCount;
  }

  get activatedPatientCount(): number {
    return this.props.activatedPatientCount;
  }

  get archivedPatientCount(): number {
    return this.props.archivedPatientCount;
  }

  get emailVerified(): boolean {
    return this.props.emailVerified;
  }

  get firstName(): string {
    return this.props.firstName;
  }

  get lastName(): string {
    return this.props.lastName;
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get initials(): string {
    return `${this.firstName.length > 0 ? this.firstName.charAt(0) : ''}${
      this.lastName.length > 0 ? this.lastName.charAt(0) : ''
    }`;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get phoneNumber(): string {
    return this.props.phoneNumber;
  }

  get address(): string {
    return this.props.address;
  }

  get postalCode(): string {
    return this.props.postalCode;
  }

  get city(): string {
    return this.props.city;
  }

  get country(): string {
    return this.props.country;
  }

  get encouragementCount(): number {
    return this.props.encouragementCount;
  }

  get picture(): Picture | undefined {
    return this.props.picture;
  }

  get subscription(): Subscription | undefined {
    return this.props.subscription;
  }

  get subscriptionFeatures():
    | { [key: string]: SubscriptionFeature }
    | undefined {
    return this.props.subscriptionFeatures;
  }

  get isAdmin(): boolean {
    return this.props.isAdmin ?? false;
  }

  get msdpId(): string | undefined {
    return this.props.msdpId;
  }

  get msdpUser(): string | undefined {
    return this.props.msdpUser;
  }

  get msdpPassword(): string | undefined {
    return this.props.msdpPassword;
  }

  get msdpLinkedAt(): Date | undefined {
    return this.props.msdpLinkedAt;
  }

  get msdpKey(): string | undefined {
    return this.props.msdpKey;
  }

  get lastNews(): Date | undefined {
    return this.props.lastNews;
  }

  get status(): string | undefined {
    return this.props.status;
  }

  get adeliNumber(): string | undefined {
    return this.props.adeliNumber;
  }

  get patientsDefaultSort():
    | 'createdAt'
    | 'updatedAt'
    | 'lastActiveDate'
    | undefined {
    return this.props.patientsDefaultSort;
  }

  set patientsDefaultSort(
    value: 'createdAt' | 'updatedAt' | 'lastActiveDate' | undefined,
  ) {
    this.props.patientsDefaultSort = value;
  }

  private constructor(props: DietitianProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: DietitianProps): Dietitian {
    return Dietitian.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  updatePicture(picture: Picture | null): Dietitian {
    return this.copyWith({ picture } as DietitianProps);
  }

  updatePatientCount(
    patientCount: number,
    activatedPatientCount: number,
    archivedPatientCount: number,
  ): Dietitian {
    return this.copyWith({
      patientCount,
      activatedPatientCount,
      archivedPatientCount,
    } as DietitianProps);
  }

  public static create(props: DietitianProps, id?: UniqueEntityID): Dietitian {
    return new Dietitian(props, id);
  }
}
