import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

export interface MessageProps {
  message?: string;
  createdAt?: Date;
  mimeType?: string;
  recipientId: UniqueEntityID;
  url?: string;
  hasMedia: boolean;
  resourcePath?: string;
  senderId: UniqueEntityID;
}

export class MessageId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): MessageId {
    return new MessageId(id);
  }
}

export class Message extends Entity<MessageProps> {
  get messageId(): MessageId {
    return MessageId.create(this.id);
  }

  get message(): string | undefined {
    return this.props.message;
  }

  get mimeType(): string | undefined {
    return this.props.mimeType;
  }

  get url(): string | undefined {
    return this.props.url;
  }

  get hasMedia(): boolean {
    return this.props.hasMedia;
  }

  get resourcePath(): string | undefined {
    return this.props.resourcePath;
  }

  get senderId(): UniqueEntityID {
    return this.props.senderId;
  }

  get recipientId(): UniqueEntityID {
    return this.props.recipientId;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  private constructor(props: MessageProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: MessageProps): Message {
    return Message.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: MessageProps, id?: UniqueEntityID): Message {
    return new Message(props, id);
  }
}
