import { Component, EventEmitter, Input, Output } from '@angular/core';
import moment from 'moment';

export enum ProgramedEncouragementDestination {
  Group = 'group',
  Patient = 'patient',
  All = 'all',
}

@Component({
  selector: '[msd-programed-encouragement-cell]',
  templateUrl: './programed-encouragement-cell.component.html',
  styleUrls: ['./programed-encouragement-cell.component.scss'],
})
export class ProgramedEncouragementCellComponent {
  @Input() public message: string;
  @Input() public destination?: string;
  @Input() public programedDate: Date;
  @Input() public isActionLoading: boolean;
  @Output() public onDelete = new EventEmitter<void>();

  get momentDate() {
    return moment(this.programedDate);
  }

  public get formattedDate(): string {
    return this.momentDate.format('DD.MM.yyyy');
  }

  public get formattedHour(): string {
    return this.momentDate.format('HH[h]mm');
  }
}
