import Entity from '../../../../core/domain/entity';
import UniqueEntityID from '../../../../core/domain/unique_entity_id';
import { DietitianId } from '../dietitian';
import { Picture } from '../picture/picture';

export interface BillParamsProps {
  dietitianId: DietitianId;
  logo?: Picture | undefined;
  logoPosition?: 'left' | 'center' | 'right' | undefined;
  logoSize?: 'small' | 'medium' | 'large' | undefined;
  socialReason?: string | undefined;
  registrationNumber?: string | undefined;
  address?: string | undefined;
  zip?: string | undefined;
  city?: string | undefined;
  country?: string | undefined;
  phoneNumber?: string | undefined;
  email?: string | undefined;
  tax: 'with' | 'without';
  taxNumber?: string | undefined;
  taxRate?: number | undefined;
  noTaxMention: boolean;
  footer?: string | undefined;
  previewUrl?: string | undefined;
  createdAt?: Date | undefined;
  updatedAt?: Date | undefined;
  rcs: boolean | undefined;
  late: boolean | undefined;
  aga: boolean | undefined;
}

export class BillParams extends Entity<BillParamsProps> {
  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get logo(): Picture | undefined {
    return this.props.logo;
  }

  get logoPosition(): 'left' | 'center' | 'right' | undefined {
    return this.props.logoPosition;
  }

  get logoSize(): 'small' | 'medium' | 'large' | undefined {
    return this.props.logoSize;
  }

  get socialReason(): string | undefined {
    return this.props.socialReason;
  }

  get registrationNumber(): string | undefined {
    return this.props.registrationNumber;
  }

  get address(): string | undefined {
    return this.props.address;
  }

  get zip(): string | undefined {
    return this.props.zip;
  }

  get city(): string | undefined {
    return this.props.city;
  }

  get country(): string | undefined {
    return this.props.country;
  }

  get phoneNumber(): string | undefined {
    return this.props.phoneNumber;
  }

  get email(): string | undefined {
    return this.props.email;
  }

  get tax(): 'with' | 'without' {
    return this.props.tax;
  }

  get taxNumber(): string | undefined {
    return this.props.taxNumber;
  }

  get taxRate(): number | undefined {
    return this.props.taxRate;
  }

  get noTaxMention(): boolean {
    return this.props.noTaxMention;
  }

  get footer(): string | undefined {
    return this.props.footer;
  }

  get previewUrl(): string | undefined {
    return this.props.previewUrl;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get rcs(): boolean | undefined {
    return this.props.rcs;
  }

  get late(): boolean | undefined {
    return this.props.late;
  }

  get aga(): boolean | undefined {
    return this.props.aga;
  }

  private constructor(props: BillParamsProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: BillParamsProps): BillParams {
    return BillParams.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: BillParamsProps,
    id?: UniqueEntityID,
  ): BillParams {
    return new BillParams(props, id);
  }
}
