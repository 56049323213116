import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { Template } from './template';

export interface EmailProps {
  from?: string;
  replyTo?: string;
  to: string[];
  cc?: string[];
  bcc?: string[];
  subject?: string;
  textMessage?: string;
  htmlMessage?: string;
  attachments?: string[];
  template?: Template;
}

export class EmailId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): EmailId {
    return new EmailId(id);
  }
}

export class Email extends Entity<EmailProps> {
  get emailId(): EmailId {
    return EmailId.create(this.id);
  }

  get from(): string | undefined {
    return this.props.from;
  }

  get replyTo(): string | undefined {
    return this.props.replyTo;
  }

  get to(): string[] {
    return this.props.to;
  }

  get cc(): string[] | undefined {
    return this.props.cc;
  }

  get bcc(): string[] | undefined {
    return this.props.bcc;
  }

  get subject(): string | undefined {
    return this.props.subject;
  }

  get textMessage(): string | undefined {
    return this.props.textMessage;
  }

  get htmlMessage(): string | undefined {
    return this.props.htmlMessage;
  }

  get attachments(): string[] | undefined {
    return this.props.attachments;
  }

  get template(): Template | undefined {
    return this.props.template;
  }

  private constructor(props: EmailProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: EmailProps): Email {
    return Email.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(props: EmailProps, id?: UniqueEntityID): Email {
    return new Email(props, id);
  }
}
