import Entity from 'src/app/core/domain/entity';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';

import { ProgramedEncouragementDestination } from '../../../../ui/dashboard/routes/questions/routes/encouragement/components/programed-encouragement-cell/programed-encouragement-cell.component';
import { PatientId } from '../../../patient/domain/patient';
import { DietitianId } from '../dietitian';
import { PatientGroupId } from '../patient_group/patient_group';

export enum EncouragementStatus {
  TO_PROCESS = 'TO_PROCESS',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  DELETED = 'DELETED',
}
export interface EncouragementProps {
  dietitianId: DietitianId;
  groupId?: PatientGroupId;
  patientId?: PatientId | PatientId[];
  recipientName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  scheduled: boolean;
  scheduledAt: Date;
  message: string;
  status?: EncouragementStatus;
}

export class EncouragmentId extends Entity<unknown> {
  private constructor(id?: UniqueEntityID) {
    super(null, id);
  }

  public static create(id?: UniqueEntityID): EncouragmentId {
    return new EncouragmentId(id);
  }
}

export class Encouragement extends Entity<EncouragementProps> {
  get encouragmentId(): EncouragmentId {
    return EncouragmentId.create(this.id);
  }

  get dietitianId(): DietitianId {
    return this.props.dietitianId;
  }

  get groupId(): PatientGroupId | undefined {
    return this.props.groupId;
  }

  get patientId(): PatientId | PatientId[] | undefined {
    return this.props.patientId;
  }

  get recipientName(): string | undefined {
    return this.props.recipientName;
  }

  get createdAt(): Date | undefined {
    return this.props.createdAt;
  }

  get updatedAt(): Date | undefined {
    return this.props.updatedAt;
  }

  get scheduled(): boolean {
    return this.props.scheduled;
  }

  get scheduledAt(): Date {
    return this.props.scheduledAt;
  }

  get message(): string {
    return this.props.message;
  }
  get status(): EncouragementStatus | undefined {
    return this.props.status;
  }

  get destinationType(): ProgramedEncouragementDestination {
    if (this.groupId && !this.patientId) {
      return ProgramedEncouragementDestination.Group;
    } else if (!this.groupId && this.patientId) {
      return ProgramedEncouragementDestination.Patient;
    } else {
      return ProgramedEncouragementDestination.All;
    }
  }

  private constructor(props: EncouragementProps, id?: UniqueEntityID) {
    super(props, id);
  }

  copyWith(props: EncouragementProps): Encouragement {
    return Encouragement.create(
      {
        ...this.props,
        ...props,
      },
      this.id,
    );
  }

  public static create(
    props: EncouragementProps,
    id?: UniqueEntityID,
  ): Encouragement {
    return new Encouragement(props, id);
  }
}
