import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FirebaseError } from 'firebase/app';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import UniqueEntityID from 'src/app/core/domain/unique_entity_id';
import { GenericException } from 'src/app/core/logic/exception';

import { DietitianId } from '../../dietitian/domain/dietitian';
import { PatientId } from '../../patient/domain/patient';
import { SharedDocument } from '../domain/shared_document/shared_document';
import { SharedDocumentCategory } from '../domain/shared_document/shared_document_category';
import {
  SharedDocumentNoPatientFoundException,
  SharedDocumentNotFoundException,
} from '../domain/shared_document/shared_document_exceptions';
import { SharedDocumentUpload } from '../domain/shared_document/shared_document_upload';

interface SharedDocumentSchema {
  patientId: string | null;
  patientUserId: string | null;
  dietitianId: string;
  name: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
  url: string | null;
  path: string | null;
  size: number;
  mimeType: string;
  category: SharedDocumentCategory | null;
  deleted: boolean;
  deletedAt: Timestamp | null;
  deletedByUser: string | null;
}

@Injectable()
export class SharedDocumentRepository {
  private copySharedDocumentsForPatientsFromCloud: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }[]>;
  private shareDocumentFromCloud: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }>;
  private shareNoteFromCloud: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }>;
  private shareSurveyFromCloud: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }>;
  private shareCompositionFromCloud: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }>;
  private shareCustomNutritionFromCloud: (
    data: unknown,
  ) => Observable<{ [key: string]: unknown }>;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private functions: AngularFireFunctions,
  ) {
    this.copySharedDocumentsForPatientsFromCloud = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }[]
    >('shared_document-copySharedDocumentsForPatients');

    this.shareDocumentFromCloud = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }
    >('document-shareDocument');

    this.shareNoteFromCloud = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }
    >('note-shareNote');

    this.shareSurveyFromCloud = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }
    >('survey-shareSurvey');

    this.shareCompositionFromCloud = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }
    >('composition-shareComposition');

    this.shareCustomNutritionFromCloud = this.functions.httpsCallable<
      unknown,
      { [key: string]: unknown }
    >('nutrition-shareCustomNutrition');
  }

  private collection(queryFn?: QueryFn) {
    return this.firestore.collection<SharedDocumentSchema>(
      'shared_documents',
      queryFn,
    );
  }

  private toSchema(sharedDocument: SharedDocument): SharedDocumentSchema {
    return {
      dietitianId: sharedDocument.dietitianId.id.toString(),
      patientId: sharedDocument.patientId?.id.toString() ?? null,
      patientUserId: sharedDocument.patientUserId?.id.toString() ?? null,
      name: sharedDocument.name,
      createdAt:
        sharedDocument.createdAt !== undefined
          ? Timestamp.fromDate(sharedDocument.createdAt)
          : Timestamp.now(),
      updatedAt: Timestamp.now(),
      url: sharedDocument.url ?? null,
      path: sharedDocument.path ?? '',
      size: sharedDocument.size,
      mimeType: sharedDocument.mimeType,
      category: sharedDocument.category ?? null,
      deleted: sharedDocument.deleted,
      deletedAt:
        sharedDocument.deletedAt !== undefined
          ? Timestamp.fromDate(sharedDocument.deletedAt)
          : null,
      deletedByUser: sharedDocument.deletedByUser ?? null,
    };
  }

  private fromSchema(schema: SharedDocumentSchema, id: string): SharedDocument {
    return SharedDocument.create(
      {
        dietitianId: DietitianId.create(new UniqueEntityID(schema.dietitianId)),
        patientId: schema.patientId
          ? PatientId.create(new UniqueEntityID(schema.patientId))
          : undefined,
        patientUserId: schema.patientUserId
          ? PatientId.create(new UniqueEntityID(schema.patientUserId))
          : undefined,
        name: schema.name,
        createdAt: schema.createdAt?.toDate(),
        updatedAt: schema.updatedAt?.toDate(),
        url: schema.url ?? undefined,
        path: schema.path ?? undefined,
        size: schema.size ?? -1,
        mimeType: schema.mimeType ?? 'unknown',
        category: schema.category ?? undefined,
        deleted: schema.deleted === true,
        deletedAt: schema.deletedAt?.toDate(),
        deletedByUser: schema.deletedByUser ?? undefined,
      },
      new UniqueEntityID(id),
    );
  }

  fromMap(map: { [key: string]: unknown }): SharedDocument {
    const getDate = (data: unknown) =>
      typeof data === 'string' ? new Date(data) : undefined;

    return SharedDocument.create(
      {
        dietitianId: DietitianId.create(
          new UniqueEntityID(map['dietitianId'] as string),
        ),
        patientId:
          map['patientId'] === 'string'
            ? PatientId.create(new UniqueEntityID(map['patientId'] as string))
            : undefined,
        patientUserId:
          map['patientUserId'] === 'string'
            ? PatientId.create(
                new UniqueEntityID(map['patientUserId'] as string),
              )
            : undefined,
        name: map['name'] as string,
        createdAt: getDate(map['createdAt']),
        updatedAt: getDate(map['updatedAt']),
        url: map['url'] as string | undefined,
        path: map['path'] as string | undefined,
        size: typeof map['size'] === 'number' ? map['size'] : -1,
        mimeType:
          typeof map['mimeType'] === 'string' ? map['mimeType'] : 'unknown',
        category:
          typeof map['category'] === 'string'
            ? SharedDocumentCategory[
                map['category'] as keyof typeof SharedDocumentCategory
              ]
            : undefined,
        deleted: map['deleted'] === true,
        deletedAt: getDate(map['deletedAt']),
        deletedByUser: map['deletedByUser'] as string | undefined,
      },
      new UniqueEntityID(map['id'] as string),
    );
  }

  private async fileExists(path: string): Promise<boolean> {
    let url: string;
    try {
      url = await firstValueFrom(this.storage.ref(path).getDownloadURL());
    } catch (error) {
      return false;
    }
    return url != null;
  }

  // Commenté le 6 Mars 2024 par Michael
  // private async deleteFile(path: string): Promise<void> {
  //   if (!(await this.fileExists(path))) return;
  //
  //   await firstValueFrom(this.storage.ref(path).delete());
  // }

  async load(sharedDocumentId: string): Promise<SharedDocument> {
    const snap = await firstValueFrom(
      this.collection().doc(sharedDocumentId).get(),
    );
    if (!snap.exists || snap.data == null) {
      throw new SharedDocumentNotFoundException();
    }

    return this.fromSchema(snap.data() as SharedDocumentSchema, snap.id);
  }

  async findByDietitianId(
    dietitianId: string,
    asc = false,
  ): Promise<SharedDocument[]> {
    const snap = await firstValueFrom(
      this.collection((ref) =>
        ref
          .where('dietitianId', '==', dietitianId)
          .where('deleted', '==', false)
          .orderBy('updatedAt', asc ? 'asc' : 'desc'),
      ).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async findByPatientId(
    dietitianId: string,
    patientId: string,
    category: SharedDocumentCategory | null,
    asc = false,
  ): Promise<SharedDocument[]> {
    const snap = await firstValueFrom(
      this.collection((ref) => {
        let query = ref
          .where('dietitianId', '==', dietitianId)
          .where('patientId', '==', patientId);

        if (category) {
          query = query.where('category', '==', category);
        }

        return query
          .where('deleted', '==', false)
          .orderBy('updatedAt', asc ? 'asc' : 'desc');
      }).get(),
    );

    return snap.docs.map((doc) => this.fromSchema(doc.data(), doc.id));
  }

  async create(sharedDocument: SharedDocument): Promise<SharedDocument> {
    const schema = this.toSchema(sharedDocument);
    const ref = await this.collection().add(schema);

    return this.fromSchema(schema, ref.id);
  }

  upload(sharedDocument: SharedDocument): SharedDocumentUpload {
    if (sharedDocument.path == null || sharedDocument.file == null) {
      throw new GenericException();
    }
    const fileRef = this.storage.ref(sharedDocument.path);
    const task = fileRef.put(sharedDocument.file, {
      contentType: sharedDocument.mimeType,
    });

    return SharedDocumentUpload.create({
      sharedDocument,
      task,
    });
  }

  async cancelUpload(upload: SharedDocumentUpload): Promise<void> {
    try {
      upload.cancel();
    } catch (error) {
      // do nothing
    }

    // Commenté le 6 Mars 2024 par Michael
    // if (withPurge) {
    //   if (upload.sharedDocument.path) {
    //     try {
    //       await this.deleteFile(upload.sharedDocument.path);
    //     } catch (error) {
    //       // do nothing
    //     }
    //   }
    //
    //   if (!upload.sharedDocument.createdAt) return;
    //
    //   try {
    //     await this.purge(upload.sharedDocument.sharedDocumentId.id.toString());
    //   } catch (error) {
    //     // do nothing
    //   }
    // }
  }

  // Commenté le 6 mars 2024 par Michael
  // async save(sharedDocument: SharedDocument): Promise<SharedDocument> {
  //   if (sharedDocument.path == null || sharedDocument.url == null) {
  //     throw new GenericException();
  //   }
  //
  //   if (
  //     sharedDocument.path == null ||
  //     !(await this.fileExists(sharedDocument.path))
  //   ) {
  //     throw new GenericException();
  //   }
  //
  //   // check for existing document file
  //   const snap = await firstValueFrom(
  //     this.collection().doc(sharedDocument.id.toString()).get(),
  //   );
  //   const path = snap.data()?.path;
  //   // delete old file
  //   if (path && path != sharedDocument.path) {
  //     await this.deleteFile(path);
  //   }
  //
  //   const schema = this.toSchema(sharedDocument);
  //   return this.collection()
  //     .doc(sharedDocument.sharedDocumentId.id.toString())
  //     .set(schema)
  //     .then(() =>
  //       this.fromSchema(
  //         schema,
  //         sharedDocument.sharedDocumentId.id.toString(),
  //       ).copyWith({
  //         file: sharedDocument.file,
  //       } as SharedDocumentProps),
  //     );
  // }

  async delete(sharedDocumentId: string, userId: string): Promise<void> {
    // Commenté le 6 Mars 2024 par Michael
    // const snap = await firstValueFrom(
    //   this.collection().doc(sharedDocumentId).get(),
    // );
    // if (!snap.exists || snap.data == null) {
    //   throw new SharedDocumentNotFoundException();
    // }
    // const path = snap.data()?.path;
    // if (path) {
    //   await this.deleteFile(path);
    // }

    return this.collection().doc(sharedDocumentId).update({
      deleted: true,
      deletedAt: Timestamp.now(),
      deletedByUser: userId,
      url: null,
      path: null,
      size: 0,
    });
  }

  // Commenté le 6 Mars 2024 par Michael
  // async purge(documentId: string): Promise<void> {
  //   const snap = await firstValueFrom(this.collection().doc(documentId).get());
  //   if (!snap.exists || snap.data == null) {
  //     throw new SharedDocumentNotFoundException();
  //   }
  //   const path = snap.data()?.path;
  //   if (path) {
  //     await this.deleteFile(path);
  //   }
  //
  //   return this.collection().doc(documentId).delete();
  // }

  async copySharedDocumentsForPatients(
    targetId: string | string[] | null,
    documents: SharedDocument[],
  ): Promise<SharedDocument[]> {
    try {
      const results = await firstValueFrom(
        this.copySharedDocumentsForPatientsFromCloud({
          targetId,
          sharedDocumentIds: documents.map((d) =>
            d.sharedDocumentId.id.toString(),
          ),
        }),
      );

      return results.map(this.fromMap);
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.message == 'No patient found') {
          throw new SharedDocumentNoPatientFoundException();
        }
      }
      throw error;
    }
  }

  async shareDocument(
    documentId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const result = await firstValueFrom(
      this.shareDocumentFromCloud({
        documentId,
        category,
      }),
    );

    return this.fromMap(result);
  }

  async shareNote(
    noteId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const result = await firstValueFrom(
      this.shareNoteFromCloud({
        noteId,
        category,
      }),
    );

    return this.fromMap(result);
  }

  async shareSurvey(
    surveyId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const result = await firstValueFrom(
      this.shareSurveyFromCloud({
        surveyId,
        category,
      }),
    );

    return this.fromMap(result);
  }

  async shareComposition(
    compositionId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const result = await firstValueFrom(
      this.shareCompositionFromCloud({
        compositionId: compositionId,
        category,
      }),
    );

    return this.fromMap(result);
  }

  async shareCustomNutrition(
    patientId: string,
    customNutritionId: string,
    category: SharedDocumentCategory,
  ): Promise<SharedDocument> {
    const result = await firstValueFrom(
      this.shareCustomNutritionFromCloud({
        patientId,
        customNutritionId,
        category,
      }),
    );

    return this.fromMap(result);
  }
}
