<td class="message-wrapper">
  <p class="message-text">{{message}}</p>
</td>
<td class="destination-wrapper">
  <div class="destination-content-wrapper">
    <div class="destination-badge">
      {{destination}}
    </div>
  </div>
</td>
<td class="date-wrapper">
  <span class="date-label">{{formattedDate}}</span>
</td>
<td class="hour-wrapper">
  <span class="hour-label">{{formattedHour}}</span>
</td>
<td class="actions-wrapper">
  <div class="dropdown dropdown-context-menu" dropdown>
    <button [class.disabled]="isActionLoading" id="more-button" (click)="$event.stopPropagation()" type="button"
      class="btn btn-outline-primary btn-outline icon-only circle more-action-button" dropdownToggle>
      <ng-container *ngIf="isActionLoading else moreActionButton">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        <span class="visually-hidden">Chargement...</span>
      </ng-container>
      <ng-template #moreActionButton>
        <i i-bs name="three-dots" width="15" height="15"></i>
      </ng-template>
    </button>

    <div class="dropdown-backdrop fade"></div>
    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right dropstart" role="menu">
      <li role="menuitem"><span class="dropdown-item destructive-item" id="action-delete" (click)="onDelete.emit()">Supprimer</span></li>
      <li class="divider dropdown-divider"></li>
      <li role="menuitem"><span id="action-cancel" class="dropdown-item action">Annuler</span>
      </li>
    </ul>
  </div>
</td>
